/* Expert Feadback Section Start  css*/

.expertfeesback{
    background: var(--bggrey);
  }

.feedback-box {
    background: var(--white);
    box-shadow: 0 2px 15px 0 rgba(0,0,0,.06);
    padding: 25px;
    border-radius: 5px;
    margin-top: 25px;
    z-index: 1;
    position: relative;    
    transition: 0.5s;
}
.client-info {
    position: relative;
    padding-left: 118px;
    margin-bottom: 10px;
}
.client-info .client-img {
    position: absolute;
    left: 0;
    top: -45px;
    width: 100px;
}
.client-info .client-img img {
    border-radius: 20px
}
.feedback-owl .owl-nav.disabled + .owl-dots {
    margin-top: 40px;
}
.feedback-box p{
    line-height: 1.5;
    margin-bottom: 0;
    font-size: 15px;
    transition: 0.5s;
    
}
.client-info h5{
    text-transform: capitalize;
    line-height: 1.3;
    transition: 0.5s;
}
.feedback-box:hover{
    background-color: var(--orange);
    transition: 0.5s;
}
.feedback-box:hover p, .feedback-box:hover .client-info h5{
    color: var(--white);
    transition: 0.5s;
}